/* eslint-disable */
import React from 'react'
import RedirectContainer from '@containers/RedirectContainer'
// import PropTypes from 'prop-types'
// import { Helmet } from 'react-helmet'
// import BibleLayout from '@layouts/BibleLayout'
// import FreebiePageContainer from '@containers/FreebieFacebookPage/FreebiePageContainer'
// import avaliableLangs from '@localization/avaliableLangs'
// import { freebieFacebookFormScript } from '../../../externalScriptsCode/freebieFacebookPage'

export default () => <RedirectContainer goToPath='/en/facebook' />

// NOT USED FOR NOW, REDIRECT TO /[lang]/facebook is applied
// const IndexPage = ({ location }) => (
//   <>
//     <Helmet>
//       <meta charSet='utf-8' />
//       <title>The Facebook Bible - By Patrick Wind</title>
//       <link
//         rel='canonical'
//         href='https://reports.onlinepunks.com/en/freebie/facebook'
//       />
//       <meta
//         property='og:title'
//         content='The Facebook Bible - By Patrick Wind'
//       />
//       <meta
//         property='og:description'
//         content='Facebook Marketing e-book by Patrick Wind, presented by OnlinePunks.'
//       />
//       <meta property='og:type' content='website' />
//       <meta name='twitter:card' content='summary' />
//       <meta
//         name='twitter:title'
//         content='The Facebook Bible - By Patrick Wind'
//       />
//       <meta
//         name='twitter:description'
//         content='Facebook Marketing e-book by Patrick Wind, presented by OnlinePunks.'
//       />
//       <meta
//         property='og:image'
//         content='https://reports.onlinepunks.com/fbible/Footer-Freebie@2x.png'
//       />
//       <meta
//         name='twitter:image:src'
//         content='https://cdn.infludata.com/logo.png'
//       />
//       <meta name='twitter:domain' content='infludata.com' />
//       <script
//         id='pixel-script-poptin'
//         src='https://cdn.popt.in/pixel.js?id=df783521a0109'
//         async='true'
//       />
//       <script
//         async='true'
//         dangerouslySetInnerHTML={{ __html: freebieFacebookFormScript }}
//       />
//     </Helmet>

//     <BibleLayout
//       location={location}
//       showTaxesNote={false}
//       pageLanguage={avaliableLangs.en}
//     >
//       <FreebiePageContainer location={location} />
//     </BibleLayout>
//   </>
// )

// IndexPage.propTypes = {
//   location: PropTypes.object.isRequired,
// }

// export default IndexPage
